import React, { FC, ReactElement } from 'react'
import Layout from '../components/layout'
import Container from '../components/container'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

function BandMemberGallery() {
  return null
}

const AboutPage: FC = (): ReactElement => (
  <Layout
    title="Star Kitchen - About The Band"
    canonical="/about/"
    currentNav="About"
    showNav={true}
  >
    <Container>
      <p>
        Star Kitchen is a Philadelphia-based funk supergroup that
        burst onto the scene in 2018
        with a live show based around covering, exploring and
        live remixing classics and deep cuts from the last 50 years and
        creating new original music that is equal parts mind expanding and
        soulful.
        Set lists are different every show but often include music by
        Parliament/Funkadelic, The Beatles, Prince, Led Zeppelin,
        Curtis Mayfield and many deeper cuts made famous through their
        use as samples in hip-hop beats. The band prioritizes being
        inclusive, having fun and getting down -
        inviting many guest horn players, vocalists and other musicians
        to augment the core group.
        A Star Kitchen show is like a great dim sum meal, serving up
        many dishes, often improvised based around what is fresh and
        available.
      </p>
      <p>
        The band was formed by bassist Marc Brownstein of the Disco Biscuits as a
        way to explore the funk and soul roots of his home in Philadelphia
        with other like-minded available musicians that had exceptional ability
        and positive attitudes. <a href=""></a>
      </p>
      <p>
        Drummer Marlon Lewis is a staple on the Philly funk,
        r&amp;b, hip-hop and gospel scene. He leads his own groups and has toured with
        many artists including Ms. Lauryn Hill, John Legend and French Montana.
      </p>
      <p>
        Guitarist Danny Mayer had recently relocated
        to Philadelphia and had deep roots in the funk &amp; soul revival scene
        as a member of the Eric Krasno Band and Alan Evans Group and as the founder
        of On The Spot Trio (OTS Trio).
      </p>
      <p>
        Keyboardist Rob Marscher had also recently
        moved to the Philadelphia-area and knew Marc from the jam scene going back
        to 1997 when his high school band Roadside Attraction was on a bill with the Disco Biscuits.
        Rob then was the keyboardist for Addison Groove Project (AGP), Matisyahu,
        Jennifer Hartswick Band and a frequent collaborator with the Nth Power.

        {/* diverse but overlapping expertise in underground hip-hop, space funk, blues
        rock, psychedelic r&amp;b and live electronic music. */}
      </p>
      <BandMemberGallery />
    </Container>
  </Layout>
)

export default AboutPage
